import {
  Box,
  Typography,
  Card,
  Grid,
  TextField,
  Button,
  Stack,
  useMediaQuery,
  Backdrop,
  Modal,
  Fade,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import downloadBlobAsFile from "../components/fileDownload";
import uniApi from "../api/uniApi";
import { calcDataDefault, priceServices, hwRabat, hwData } from "../appData";
import ReactGA from "react-ga4";

const HippoCalculator = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const defaultResult = {
    visible: false,
    totalItems: 0,
    hwTotal: 0,
    serviceTotal: 0,
    total: 0,
  };

  const [calcData, setCalcData] = React.useState(calcDataDefault);
  const [showResult, setShowResult] = React.useState(defaultResult);

  //state handlers for Modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    if (process.env.NODE_ENV !== "development") {
      setEmail("");
      setEmailValid(false);
    } else {
      setEmail("aa@aa.aa");
      setEmailValid(true);
    }
    setPdfGenerating(false);
  };
  const handleClose = () => setOpen(false);
  const [email, setEmail] = React.useState("");
  const [emailValid, setEmailValid] = React.useState(false);
  const [pdfGenerating, setPdfGenerating] = React.useState(false);
  const [userHwConfig, setUserHwConfig] = React.useState([]);

  const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? 400 : "90%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 4,
    p: 2,
  };

  let modalCmp = (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            color="primary"
            mb={2}
          >
            Pre vygenerovanie cenovej ponuky vo formáte PDF zadajte prosím Váš
            email
          </Typography>
          <TextField
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
            hiddenLabel
            id="userEmail"
            type="email"
            sx={{ width: "100%" }}
            value={email}
            error={!emailValid}
            onChange={(event) => {
              let isValid = true;
              const pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
              );

              if (!pattern.test(event.target.value)) {
                isValid = false;
              }
              setEmailValid(isValid);
              setEmail(event.target.value);
            }}
          />
          <Stack
            spacing={2}
            direction={matches ? "row" : "column"}
            sx={{ justifyContent: "flex-end" }}
            mt={5}
          >
            <Button variant="contained" onClick={handleClose}>
              Zavri
            </Button>
            <LoadingButton
              loading={pdfGenerating}
              disabled={!emailValid}
              variant="contained"
              onClick={() => {
                setPdfGenerating(true);
                getPdf(`CP-${new Date().getTime() % 10000}.pdf`);
              }}
            >
              Generuj PDF
            </LoadingButton>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  );

  let gridContent = calcData.map((item) => {
    if (item.type === "text") {
      return (
        <Grid
          key={item.id}
          item
          xs={12}
          sm={8}
          align={matches ? "left" : "center"}
        >
          <Typography pt={2}>{item.text}</Typography>
        </Grid>
      );
    } else {
      return (
        <Grid key={item.id} item xs={12} sm={4} align="center">
          <TextField
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
            error={item.hasError}
            hiddenLabel
            id="filled-hidden-label-normal"
            type="number"
            onChange={(event) => {
              let tmpData = [...calcData];
              for (let cdata of tmpData) {
                if (cdata.id === item.id) {
                  cdata.value = event.target.value;
                  cdata.hasError = isNaN(event.target.valueAsNumber);
                }
              }
              setCalcData(tmpData);
            }}
            value={item.value}
          />
        </Grid>
      );
    }
  });

  let priceCheckoutContent = (
    <Box
      container
      direction="column"
      sx={{
        backgroundColor: "gray.200",
        borderRadius: 4,
        padding: 1,
        paddingLeft: 3,
      }}
    >
      <Typography variant="h6" color="primary">
        Počet prvkov v systéme: {showResult.totalItems}
      </Typography>
      <Typography variant="h6" color="primary">
        Cena za hardvér: &nbsp;{showResult.hwTotal} Eur bez DPH
      </Typography>
      <Typography variant="h6" color="primary">
        Cena za služby:&nbsp;&nbsp;&nbsp; {showResult.serviceTotal} Eur bez DPH
      </Typography>
      <Typography variant="h6" color="primary">
        Celková cena: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {showResult.total} Eur bez
        DPH
      </Typography>
    </Box>
  );

  const addHwType = (arr, name) => {
    for (let item of hwData) {
      if (name === item.name) {
        let addNew = true;
        for (let a of arr) {
          if (a.name === name) {
            addNew = false;
            a.itemCounter++;
            break;
          }
        }
        if (addNew) {
          arr.push({
            ...item,
            itemCounter: 1,
          });
        }
        break;
      }
    }
  };

  const calcItems = (arr) => {
    let res = {
      di: 0,
      do: 0,
      ai: 0,
      ao: 0,
      dallas: 0,
    };
    for (let a of arr) {
      res.di += a.di * a.itemCounter;
      res.do += a.do * a.itemCounter;
      res.ai += a.ai * a.itemCounter;
      res.ao += a.ao * a.itemCounter;
      res.dallas += a.dallas * a.itemCounter;
    }
    return res;
  };

  const calcPriceHandler = async () => {
    let inputs = 0;
    let outputs = 0;
    let pwmCount = 0;
    let dallasCount = 0;
    let calcHw = [];
    for (let data of calcData) {
      if (data.type === "input") {
        if (!isNaN(data.value)) {
          inputs += parseInt(data.value) * data.inputMultiplier;
          if (data.id === "pwm") {
            pwmCount += parseInt(data.value) * data.multiplier;
          } else {
            outputs += parseInt(data.value) * data.multiplier;
          }
          if (data.id === "heating") {
            dallasCount += parseInt(data.value);
          }
        }
      }
    }
    let loopCounter = 0;
    //console.log(inputs, outputs, dallasCount, pwmCount);

    addHwType(calcHw, "M207");
    while (1) {
      const values = calcItems(calcHw);
      if (values.ao < pwmCount) {
        addHwType(calcHw, "xS51");
      }
      if (values.do < outputs || values.di < inputs) {
        addHwType(calcHw, "xS11");
      }
      if (values.dallas < dallasCount) {
        addHwType(calcHw, "xG18");
      }
      if (loopCounter++ > 50) {
        break;
      }
    }
    for (let i = 0; i < dallasCount; i++) {
      addHwType(calcHw, "dallas");
    }
    addHwType(calcHw, "zdroj");

    let price = 0;
    let totalItems = inputs + outputs + dallasCount + pwmCount;
    for (let a of calcHw) {
      a.price = Math.floor(a.price * hwRabat);
      price += a.itemCounter * a.price;
    }
    let serviceTotal = priceServices[0].itemPrice * totalItems;
    for (let s of priceServices) {
      if (totalItems > s.items) {
        serviceTotal = s.itemPrice * totalItems;
      }
    }
    setUserHwConfig(calcHw);
    if (totalItems > 0) {
      const resObj = {
        visible: true,
        totalItems: Math.floor(totalItems),
        hwTotal: Math.floor(price),
        serviceTotal: Math.floor(serviceTotal + 200),
        total: Math.floor(price) + Math.floor(serviceTotal + 200),
      };
      setShowResult(resObj);
      const response = await uniApi.post("/pdf/cpGenData", {
        content: {
          ...resObj,
        },
        email: "",
        userHwConfig: [],
        calcData: [],
      });
      ReactGA.event({
        category: "CP",
        action: "calc_cp",
        label: resObj.totalItems, // optional
        value: resObj.total, // optional, must be a number
      });
    } else {
      setShowResult(defaultResult);
    }
  };

  const clearCalcHandler = () => {
    let tmpData = [...calcData];
    for (let cdata of tmpData) {
      cdata.value = 0;
    }
    setCalcData(tmpData);
    setShowResult(defaultResult);
  };

  const getPdf = async (filename) => {
    const pdfData = {
      ...showResult,
    };
    try {
      let res = await uniApi.post(
        "/pdf",
        {
          content: pdfData,
          email,
          userHwConfig,
          calcData,
        },
        {
          responseType: "blob",
        }
      );

      if (res.headers["content-disposition"]) {
        let headerName = res.headers["content-disposition"];
        let namePos = headerName.indexOf("filename=");
        if (namePos !== -1) {
          filename = headerName.substring(namePos + 10);
          if (filename.slice(-1) === `"`) {
            filename = filename.substring(0, filename.length - 1);
          }
        }
      }
      if (res.status === 200) {
        downloadBlobAsFile(res.data, filename, "application/pdf");
      }
      setPdfGenerating(false);
      setOpen(false);
    } catch (err) {
      setPdfGenerating(false);
      setOpen(false);
    }
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page_title: "Hippo calculator" });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
      mb={5}
      ml={3}
      mr={3}
    >
      <Typography variant="h3" color="primary" align="center" mt={1}>
        Kalkulačka systému Hippo home
      </Typography>
      <Card
        sx={{
          width: "100%",
          maxWidth: "50rem",
          alignSelf: "center",
          padding: 5,
          borderRadius: 4,
          marginTop: 2,
        }}
      >
        <Grid
          container
          spacing={1}
          sx={{
            justifyContent: "center",
            alignSelf: "center",
            alignItems: "center",
          }}
          mb={2}
        >
          {gridContent}
        </Grid>
        {showResult.visible && priceCheckoutContent}
        <Stack
          spacing={2}
          direction={matches ? "row" : "column"}
          sx={{ justifyContent: "flex-end" }}
          mt={5}
        >
          <Button variant="contained" onClick={clearCalcHandler}>
            Vynuluj kalkulačku
          </Button>
          <Button
            disabled={!showResult.visible}
            variant="contained"
            onClick={handleOpen}
          >
            Exportuj do PDF
          </Button>
          <Button variant="contained" onClick={calcPriceHandler}>
            Vypočítaj ceny
          </Button>
        </Stack>
      </Card>
      {modalCmp}
    </Box>
  );
};

export default HippoCalculator;
