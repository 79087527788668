import Reac from "react";
import { Container, Box, useMediaQuery, CardMedia, Link } from "@mui/material";
import Typography from "@mui/material/Typography";
import NavBar from "./NavBar";
import { useTheme } from "@mui/material/styles";

const Layout = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  function Copyright() {
    return (
      <Box
        sx={{
          bgcolor: "gray.400",
        }}
      >
        <Container
          maxWidth="xl"
          disableGutters
          sx={{
            bgcolor: "gray.400",
            flexDirection: matches ? "row" : "column",
            display: "flex",
            justifyContent: "space-between",
            padding: 2,
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              flexDirection: matches ? "row" : "column",
              display: "flex",
              flexGrow: 1,
            }}
          >
            <CardMedia
              component="img"
              style={{ width: 50, height: 50, marginRight: 10 }}
              image="/assets/icon.png"
              alt="hippo Icon"
            />
            <Typography variant="h5" color="primary">
              Kontaktné údaje:
            </Typography>
            <Box>
              <Typography
                variant="h6"
                color="primary.dark"
                ml={matches ? 2 : 0}
              >
                iHouse control s.r.o.
              </Typography>
              <Typography
                variant="body1"
                color="primary.dark"
                ml={matches ? 2 : 0}
              >
                Radlinského 17/B
              </Typography>
              <Typography
                variant="body1"
                color="primary.dark"
                ml={matches ? 2 : 0}
              >
                05201 Spišská Nová Ves
              </Typography>
            </Box>
            <Box ml={matches ? 2 : 0}>
              <Typography variant="h6" color="primary.dark">
                Informácie, obchod
              </Typography>
              <Typography variant="body1" color="primary.dark" ml={2}>
                <Link href="tel:+421918825442" variant="body2">
                  +421 918 825 442
                </Link>
              </Typography>
              <Link href="mailto:info@ihcontrol.eu" variant="body2" ml={2}>
                info@ihcontrol.eu
              </Link>
            </Box>
          </Box>
          <Typography
            variant="body2"
            color="text.secondary"
            align="right"
            pt={2}
            sx={{ alignSelf: "center" }}
          >
            {"Copyright © iHouse control s.r.o."}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Container>
      </Box>
    );
  }
  return (
    <Container disableGutters maxWidth="xl" maxWidth={false}>
      <NavBar />
      {props.children}
      <Copyright />
    </Container>
  );
};

export default Layout;
