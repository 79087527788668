export const appServicesList = [
  {
    id: "hw",
    nextRoute: "/pageHw",
    mainText: "Hardvérové vybavenie",
    description:
      "Prístup do Vašeho domova máte pod kontrolou v jednom prehľadnom okne aplikácie",
    paragraphs: [
      {
        mainText: "Prvotriedny hardvér",
        details: "",
      },
      {
        mainText: "Oskúšaný v priemysle",
        details: "",
      },
      {
        mainText: "Vývoj a výroba v ČR - Unipi technology",
        details: "",
      },
      {
        mainText: "Unipi - je open-source",
        details: "",
      },
      {
        mainText: "Riadiace jednotky a rozširovacie moduly",
        details: "",
      },
    ],
    imageUrl: "url(/assets/hw_background.png)",
    iconName: "/assets/hw_icon.png",
    serviceImage: "/assets/hw_background.png",
    flexContentPosition: "flex-start",
  },
  {
    id: "lightning",
    nextRoute: "/pageLightning",
    mainText: "Osvetlenie",
    description:
      "Čaro svetla - ovládajte a automatizujte svetlá pomocou systému Hippo  home s personalizovaným pôdorysom pre každého",
    paragraphs: [
      {
        mainText: "Klasické osvetlenie",
        details:
          "Ledky, halógeny, Hippo home zvládne ováldať všetko čo sa dá vypnúť a zapnúť",
      },
      {
        mainText: "Stmievateľné osvetlenie",
        details:
          "Nastavte si ideálnu intenzitu osvetlenia so stmievateľnými žiarovkami",
      },
      // {
      //   mainText: "Svetelné scény",
      //   details:
      //     "Navodte tu pravú svetelnu pohodu pre rôzne činnosti pomocou svetelných scén",
      // },
      {
        mainText: "Automatické zapínanie na pohyb",
        details:
          "Automatizujte zapínanie svetiel na chodbe alebo v iných miestnostiach pomocou detektoru pohybu",
      },
      {
        mainText: "Režim deň/noc",
        details:
          "Chcete aby niektoré svetlá fungovali len v noci alebo len cez deň? Nie je problém, pomocou súmrakového spínača to vie systém Hippo home jednoducho zariadiť. Nechcete investovať desiatky Eur do snímača? Nevadí, systém si vie na základe polohy automaticky zistiť čas súmraku a úsvit a podľa toho aplikovať pravidlá pre noc a deň",
      },
      {
        mainText: "Časový režim",
        details:
          "Chcete aby určité pravidlá pre osvetlenie fungovali len v určitom čase? Na to je ako stvorený modul časového režimu Hippo home systému",
      },
    ],
    imageUrl: "url(/assets/main_lightning.png)",
    iconName: "/assets/lights_icon.png",
    serviceImage: "/assets/lights.png",
    flexContentPosition: "flex-end",
  },
  {
    id: "doorman",
    nextRoute: "/pageDoorman",
    mainText: "Prístupový systém",
    description:
      "Prístup do Vašeho domova máte pod kontrolou v jednom prehľadnom okne aplikácie",
    paragraphs: [
      {
        mainText: "Prístupové brány",
        details:
          "Aktuálny stav prístupovej brány na pozemok, otváranie/zatváranie brány je s Hippo systémom hračka",
      },
      {
        mainText: "Garážové brány",
        details:
          "Integráciou garážovej brány do systému si možete zautomatizovať príchod a odchod z domu",
      },
      {
        mainText: "Vchodové dvere",
        details:
          "Otvárateľné vchodové dvere? Uzamykatelné vchodové dvere? Aktuálny stav, či sú dvere otvorené alebo zatvorené? Všetko v jednom s aplikáciu Hippo home",
      },
      {
        mainText: "Kryty bazénov",
        details:
          "Máte doma bazén a chceli by ste ho ovládať z mobilnej aplikácie? Vyskúšajte si to s Hippo home systémom",
      },
      {
        mainText: "Príchodový režim",
        details:
          "Automatizovanie príchodu domov je jednou z výhod použitia inteligentného domu Hippo home. Systém dokážie nie len naraz otvoriť viacero brán, ale dokáže napríklad otvoriť žalúzie, zapnúť kúrenie, vysvietiť cestu do kuchyne a rôzne iné požiadavky podľa potrieb zákazníka",
      },
      {
        mainText: "Odchodový režim",
        details:
          "Pri odchode z domu už nemusíte kontrolovať či ste za sebou zamkli alebo zavreli všetky brány. Okrem toho je možné do odchodového režimu vypnúť svetlá, utlmiť kúrenie alebo zatiahnuť žalúzie",
      },
    ],
    imageUrl: "url(/assets/main_doorman.png)",
    iconName: "/assets/doorman_icon.png",
    serviceImage: "/assets/doorman.png",
    flexContentPosition: "flex-start",
  },
  {
    id: "heating",
    nextRoute: "/pageHeating",
    mainText: "Kúrenie & chladenie",
    description: "Teplená pohoda pre každého",
    paragraphs: [
      {
        mainText: "Zónová regulácia",
        details:
          "Rôzna teplená pohoda v rôznych miestostiach? Nie je problém, pomocou zónovej regulácie si možete nastaviť v každej zóne rôznu teplotu",
      },
      {
        mainText: "Temperovanie",
        details:
          "Mnohé moderné nízkoenergetické domy používajú pre vykurovanie svojho domova podlahové kúrenie. Preto aby podlaha bola stále príjemne teplá implementuje systém Hippo home automatické temperovanie",
      },
      {
        mainText: "Časové programy",
        details:
          "Pomocou termostatu si môžete regulovať teplotu vo Vašom domove tak ako Vám vyhovuje",
      },
      {
        mainText: "Dovolenkový režim",
        details:
          "Alebo inak nazvaný aj ako Eko režim možete využiť pre rýchle prepnutie domu do útlmového režimu vykurovania, kde sa dom temperuje na nižšiu teplotu, ktorú si vopred nastavíte",
      },
      {
        mainText: "Chladenie",
        details:
          "V letných mesiacoch dobre padne si svoj domov vychlaiť na príjemnú teplotu. V rámci systémového modulu Kúrenie dokáže systém implementovať aj chladenie, pre ktoré platia rovnaké možnosti použitia ako pre kúrenie.",
      },
    ],
    imageUrl: "url(/assets/main_heating.png)",
    iconName: "/assets/heating_icon.png",
    serviceImage: "/assets/heating.png",
    flexContentPosition: "flex-end",
  },
  {
    id: "security",
    nextRoute: "/pageSecurity",
    mainText: "Zabezpečovací systém",
    description: "Bezpečnosť Vašeho domova máte pod kontrolou",
    paragraphs: [
      {
        mainText: "Zabezpečenie objektu",
        details:
          "Zabezpečenie Vašeho domova je samozrejmosťou počas neprítomnosti, či už keď ide o dovolenku alebo dlhšiu návštevu u blízkych. Počas neprítomnosti sa nemusíte obávať o bezpečnosť Vašeho domova, postará sa za Vás o to systém Hippo home",
      },
      {
        mainText: "Detekcia pohybu",
        details:
          "Absolútna kontrola a detekcia pohybu vo Vašom domove - prehľad aktívnych a neaktívnych detektorov pohybu sa nachádza v pôdoryse osvetlenia",
      },
      {
        mainText: "Poplašný systém",
        details:
          "Pomocou GSM modulu Vám váš dom oznámy narušenie a pomocou sirény spustí poplach v dome",
      },
      {
        mainText: "Integrácia zabezpečovacieho systému tretích strán",
        details:
          "Chcete iné riešenie zabezpečenia Vašeho domova? Implementácia systémov tretích strán ako Jablotron je u nás jednoduché",
      },
    ],
    imageUrl: "url(/assets/main_security.png)",
    iconName: "/assets/security_icon.png",
    serviceImage: "/assets/security.png",
    flexContentPosition: "flex-start",
  },
  {
    id: "shutters",
    nextRoute: "/pageShutters",
    mainText: "Žalúzie, markízy a rolety",
    description: "",
    paragraphs: [
      {
        mainText: "Ovládanie žalúzií",
        details:
          "Integrované ovládanie žalúzií z vypínačov alebo pomocou aplikácie. Ovládanie viacerých žalúzií súčasne",
      },
      {
        mainText: "Ranný svetelný budík",
        details:
          "Nie je príjemnejšie vstávanie ako pomocou slnečných lúčov. Vyskúšajte to pomocou svetelného budíku",
      },
      {
        mainText: "Automatizovanie akcií",
        details:
          "Automatické zatiahnutie žalúzií pred spaním? Nastavenie žalúzie do určitej polohy v danom čase? Všetko je možné nastaviť pomocou časovača",
      },
      {
        mainText: "Zatiahnutie celého domu pri odchode",
        details:
          "V rámci odchodového režimu systém môže automaticky zatieniť všetky žalúzie na dome. Tak isto je možné vyhradiť jeden vypínač na odtienenie alebo zatienenie celého domu",
      },
      {
        mainText: "Ochrana pri silnom vetre",
        details:
          "V prípade silného vetru sa systém automaticky postará o vytiahnutie žalúzií a týmto sa predíde možnému poškodeniu majetku",
      },
    ],
    imageUrl: "url(/assets/main_shutters.png)",
    iconName: "/assets/shutter_icon.png",
    serviceImage: "/assets/blinds.png",
    flexContentPosition: "flex-end",
  },
  {
    id: "watering",
    nextRoute: "/pageWatering",
    mainText: "Zavlažovanie",
    description: "Dokonála kontrola zavlažovania svojej záhradky a trávniku",
    paragraphs: [
      {
        mainText: "Zónové polievanie",
        details:
          "Kontrola zavlažovania na základe zón dokonale pokryje potreby rôznych rastlín",
      },
      {
        mainText: "Až 4 rôzne časy polievania pre zónu počas dňa",
        details:
          "Počas dňa je možné nastaviť až 4 rôzne časy polievania, pričom je možné špecifikovať dni, kedy sa bude polievať. Týmto spôsobom možete zavlažovať napríklad iba ráno a večer a predítete možnému zpáleniu trávnika",
      },
      {
        mainText: "Manuálne zapnutie zavlažovanie",
        details:
          "Zdá sa Vám záhradka alebo trávnik nedostatočne poliaty? Spustite kedykoľvek zavlažovanie manuálne",
      },
      {
        mainText: "Postupné zavlažovanie všetkých zón",
        details:
          "Ste lenivý nastavovať alebo ovládať jednotlivé zóny samostatné? Využite našu funkciu postupného zavlažovania, kde systém automaticky postupne zavlaží jednotlivé zóny",
      },
      {
        mainText: "Detektor dažďa",
        details:
          "V prípade dažďa nie je potrebné spúštať zavlažovanie. Systém pomocou detektora dažďa zistí že prší a nezapne zavlažovanie",
      },
    ],
    imageUrl: "url(/assets/main_watering.png)",
    iconName: "/assets/watering_icon.png",
    serviceImage: "/assets/watering.png",
    flexContentPosition: "flex-start",
  },
  {
    id: "systemFunction",
    nextRoute: "/pageSystemFunction",
    mainText: "Ostatné funkcie systému Hippo home",
    description:
      "Nekonečné možnosti integrovania rôznych služieb do systému a automatizovanie rôznych denno denných aktivít",
    paragraphs: [
      {
        mainText: "Ovládanie rekuperácie",
        details:
          "Efektívna výmena vzduchu je dôležitá nie len pre ľudské zdravie, ale tiež šetrí rodinný rozpočet",
        detailsVisible: true,
        serviceImage: "/assets/rekuperacia.png",
      },
      {
        mainText: "Meteo stanica",
        details:
          "Vždy aktuálne informácie o počasí v okolí Vašeho domova pomocou súkromnej meteo stanice Giom3000",
        detailsVisible: true,
        serviceImage: "/assets/meteo.png",
      },
      {
        mainText: "Časovač",
        details:
          "Automatizovanie úloh je teraz pre Vás hračka pomocou funkcie Časovača, ktorý spoľahlivo vykoná danú akciu v správny čas",
        detailsVisible: true,
        serviceImage: "/assets/casovac.png",
      },
      {
        mainText: "Scenáre",
        details:
          "Automatizovaním viacerých funkcií do jedného scenára dosiahnete maximálny používateľský komfort, ktorý dokáže inteligentný dom ponúknuť",
        detailsVisible: true,
        serviceImage: "/assets/scenare.png",
      },
      {
        mainText: "Príchodový režim",
        details: "",
        detailsVisible: false,
      },
      {
        mainText: "Odchodový režim",
        details: "",
        detailsVisible: false,
      },
    ],
    imageUrl: "",
    iconName: "/assets/system_icon.png",
    serviceImage: "",
    flexContentPosition: "flex-end",
  },
];

export const findService = (servicePath) => {
  for (let service of appServicesList) {
    if (service.nextRoute === location.pathname) {
      return {
        ...service,
      };
    }
  }
};

export const calcDataDefault = [
  {
    id: "lightsT",
    text: "Počet svetiel",
    type: "text",
    value: "0",
  },
  {
    id: "lights",
    text: "Počet svetiel",
    type: "input",
    value: "0",
    hasError: false,
    multiplier: 1,
    inputMultiplier: 1.2,
  },
  {
    id: "pwmT",
    text: "Počet stmievatelnych svetiel",
    type: "text",
    value: "0",
  },
  {
    id: "pwm",
    text: "Počet stmievatelnych svetiel",
    type: "input",
    value: "0",
    hasError: false,
    multiplier: 1,
    inputMultiplier: 2,
  },
  {
    id: "pirT",
    text: "Počet detektorov pohybu",
    type: "text",
    value: "0",
  },
  {
    id: "pir",
    text: "",
    type: "input",
    value: "0",
    hasError: false,
    multiplier: 0,
    inputMultiplier: 1,
  },
  {
    id: "doormanT",
    text: "Počet ovládatelných brán",
    type: "text",
    value: "0",
  },
  {
    id: "doorman",
    text: "",
    type: "input",
    value: "0",
    hasError: false,
    multiplier: 2,
    inputMultiplier: 0,
  },
  {
    id: "heatingT",
    text: "Počet zón vykurovania",
    type: "text",
    value: "0",
  },
  {
    id: "heating",
    text: "",
    type: "input",
    value: "0",
    hasError: false,
    multiplier: 1,
    inputMultiplier: 0,
  },
  {
    id: "shutterT",
    text: "Počet žalúzií a otvárateľných okien",
    type: "text",
    value: "0",
  },
  {
    id: "shutter",
    text: "",
    type: "input",
    value: "0",
    hasError: false,
    multiplier: 2,
    inputMultiplier: 2,
  },
  {
    id: "securityT",
    text: "Počet zón zabezpečenia",
    type: "text",
    value: "0",
  },
  {
    id: "security",
    text: "",
    type: "input",
    value: "0",
    hasError: false,
    multiplier: 2,
    inputMultiplier: 0,
  },

  {
    id: "wateringT",
    text: "Počet zón zavlažovania",
    type: "text",
    value: "0",
  },
  {
    id: "watering",
    text: "",
    type: "input",
    value: "0",
    hasError: false,
    multiplier: 1,
    inputMultiplier: 0,
  },
];

export const priceServices = [
  {
    items: 50,
    itemPrice: 13,
  },
  {
    items: 100,
    itemPrice: 10,
  },
  {
    items: 150,
    itemPrice: 9,
  },
  {
    items: 200,
    itemPrice: 8,
  },
];

export const hwRabat = 1.3;
const sale = 0.8;

export const hwData = [
  {
    type: "unipi",
    name: "S207",
    pdfName: "Hippo server S207",
    controller: true,
    price: 429 * sale,
    di: 8,
    do: 8,
    ai: 0,
    ao: 0,
    dallas: 0,
    imagePath: "/assets/s207.png",
    details:
      "Programovatelný logický kontrolér a riadiace centrum Hippo systému, ktoré môže priamo obsluhovať 8 výstupných a 8 vstupných prvkov. Obsahuje zbernicu RS485, cez ktorú sa pripájajú ďaľšie rozšírenia na zvýšenie počtu kontrolovaných vstupno výstupných prvkov.",
  },
  {
    type: "unipi",
    name: "M207",
    pdfName: "Hippo server M207",
    controller: true,
    price: 511 * sale,
    di: 20,
    do: 18,
    ai: 1,
    ao: 1,
    dallas: 1,
    imagePath: "/assets/m207.png",
    details:
      "Programovatelný logický kontrolér a riadiace centrum Hippo systému, ktoré môže priamo obsluhovať 20 výstupných a 14 vstupných prvkov. Obsahuje zbernicu RS485, cez ktorú sa pripájajú ďaľšie rozšírenia na zvýšenie počtu kontrolovaných vstupno výstupných prvkov.",
  },
  {
    type: "unipi",
    name: "L207",
    pdfName: "Hippo server L207",
    controller: true,
    price: 644 * sale,
    di: 36,
    do: 28,
    ai: 1,
    ao: 1,
    dallas: 1,
    imagePath: "/assets/l207.png",
    details:
      "Programovatelný logický kontrolér a riadiace centrum Hippo systému, ktoré môže priamo obsluhovať 36 výstupných a 28 vstupných prvkov. Obsahuje zbernicu RS485, cez ktorú sa pripájajú ďaľšie rozšírenia na zvýšenie počtu kontrolovaných vstupno výstupných prvkov.",
  },
  {
    type: "unipi",
    name: "xS11",
    controller: false,
    extension: true,
    pdfName: "Hippo modul xS11",
    price: 188 * sale,
    di: 12,
    do: 13,
    ai: 0,
    ao: 0,
    dallas: 0,
    imagePath: "/assets/xS11.png",
    details:
      "Unipi Extension xS11 je rozširujúci modul s komunikáciou protokolom Modbus RTU po zbernici RS485. Rozširuje existujúci systém o vstupno (12) výstupne prvky (13) typu vypni/zapni.",
  },
  {
    type: "unipi",
    name: "xS51",
    controller: false,
    extension: true,
    pdfName: "Hippo modul xS51",
    price: 230 * sale,
    di: 4,
    do: 5,
    ai: 4,
    ao: 4,
    dallas: 0,
    imagePath: "/assets/xS51.png",
    details:
      "Unipi Extension xS51 je rozširujúci modul s komunikáciou protokolom Modbus RTU po zbernici RS485. Rozširuje existujúci systém o vstupno (4) výstupne prvky (5) typu vypni/zapni a hlavne o analógové vstupy (4) a výstupy (4) -> stmievateľné svetlá.",
  },
  {
    type: "unipi",
    name: "xG18",
    controller: false,
    extension: true,
    pdfName: "Hippo modul xG18",
    price: 98 * sale,
    di: 0,
    do: 0,
    ai: 0,
    ao: 0,
    dallas: 8,
    imagePath: "/assets/xG18.png",
    details:
      "Unipi Extension xG18 je rozširujúci modul s komunikáciou protokolom Modbus RTU po zbernici RS485. Rozširuje existujúci systém senzory teploty typu 1-wire Dallas s precizným meraním teploty",
  },

  {
    type: "hw",
    name: "dallas",
    controller: false,
    extension: false,
    pdfName: "Teplotný senzor",
    price: 8,
    di: 0,
    do: 0,
    ai: 0,
    ao: 0,
    dallas: 0,
    imagePath: "/assets/dallas.png",
    details:
      "Digitálny, vode odolný 1-Wire teplotný senzor vybavený čipom DS18B20 s precíznym meraním teploty v rozmedzí -55 °C do 85 °C.",
  },
  {
    type: "unipi",
    name: "zdroj",
    controller: false,
    extension: false,
    pdfName: "Zdroj",
    price: 24 * sale,
    di: 0,
    do: 0,
    ai: 0,
    ao: 0,
    dallas: 0,
    imagePath: "/assets/zdroj.png",
    details: "Napájací zdroj na DIN lištu 24 V DC, 1.5A",
  },
];
