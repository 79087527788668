import {
  Box,
  Typography,
  CardMedia,
  Card,
  CardContent,
  useMediaQuery,
  Link,
  Container,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ReactGA from "react-ga4";
import { useEffect } from "react";

const AppStorePage = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page_title: "appStorePage" });
  }, []);
  return (
    <Container
      disableGutters
      sx={{
        flexDirection: matches ? "row" : "column",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        flexGrow: 1,
        mb: 2,
        mt: 3,
      }}
    >
      <CardMedia
        sx={{
          ".blur-up": {
            WebkitFilter: "blur(5px)",
            filter: "blur(5px)",
            transition: "filter 400ms, -webkit-filter 400ms",
          },
          ".blur-up.lazyloaded ": {
            WebkitFilter: "blur(0)",
            filter: "blur(0)",
          },
          maxWidth: 400,
        }}
        component="img"
        image="/assets/appHome.png"
        alt="appHome"
      />
      <Box flexDirection="column" mt={2} ml={matches ? 5 : 0}>
        <Typography color="primary" variant="h3" align="center" mb={4}>
          Vyskúšajte si našu aplikáciu pre Iphone alebo Android
        </Typography>
        <Box
          sx={{
            flexDirection: matches ? "row" : "column",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Card sx={{ maxWidth: 345, borderRadius: 5 }}>
            <CardContent>
              <Typography
                gutterBottom
                variant="h4"
                component="div"
                align="center"
                color="primary.dark"
              >
                <Link
                  underline="none"
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.ihcontrol.hippoHome"
                >
                  Google play
                </Link>
              </Typography>
            </CardContent>
            <CardMedia
              component="img"
              alt="green iguana"
              //   height="140"
              image="/assets/playStore.png"
            />
          </Card>
          <Card sx={{ maxWidth: 345, borderRadius: 5, m: 5 }}>
            <CardContent>
              <Typography
                gutterBottom
                variant="h4"
                component="div"
                align="center"
                color="primary.dark"
              >
                <Link
                  underline="none"
                  target="_blank"
                  href="https://apps.apple.com/sk/app/hippo-home-2/id1542173265#?platform=iphone"
                >
                  Apple store
                </Link>
              </Typography>
            </CardContent>
            <CardMedia
              component="img"
              alt="green iguana"
              //   height="140"
              image="/assets/appStore.png"
            />
          </Card>
        </Box>
      </Box>
    </Container>
  );
};

export default AppStorePage;
