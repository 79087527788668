import { Typography, Box, CardMedia, Grid, Container } from "@mui/material";
import React, { useEffect } from "react";
import CardPage from "./CardPage";
import ReactGA from "react-ga4";

const PageService = (props) => {
  let content = props.pageData.map((item) => {
    return (
      <CardPage
        mainText={item.mainText}
        details={item.details}
        key={item.mainText}
      />
    );
  });
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page_title: props.header });
  }, []);
  return (
    <Container
      maxWidth="xl"
      disableGutters
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <Typography variant="h2" color="primary" mt={2} pl={2} pr={2}>
        {props.header}
      </Typography>
      <Typography variant="h6" color="primary" m={2}>
        {props.mainText}
      </Typography>

      <Grid
        container
        spacing={1}
        sx={{
          justifyContent: "center",
          alignSelf: "center",
          alignItems: "center",
        }}
        mb={2}
      >
        <Grid
          item
          sm={12}
          md={props.isLandscape ? 8 : 6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignSelf: "flex-start",
            mt: 5,
            // bgcolor: "green",
          }}
        >
          <CardMedia
            sx={{
              maxWidth: props.isLandscape ? 1000 : 400,
            }}
            component="img"
            image={props.imagePath}
            alt="lights"
          />
        </Grid>
        <Grid
          item
          sm={12}
          md={props.isLandscape ? 4 : 5}
          sx={{
            display: "flex",
            justifyContent: "center",
            //bgcolor: "red",
          }}
        >
          <Box>{content}</Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PageService;
