import { Card, CardContent, CardMedia, Box, Typography } from "@mui/material";
import React from "react";

const CardHw = (props) => {
  //console.log(props.fixedHeight);
  return (
    <Card
      sx={{
        margin: 4,
        marginBottom: 1,
        borderRadius: 5,
        height: props.fixedHeight ? 150 : undefined,
        ...props.sx,
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h5"
          component="div"
          color="primary"
          align="center"
          mb={1}
        >
          {props.mainText}
        </Typography>
        <CardMedia
          sx={{
            maxWidth: 400,
            alignSelf: "center",
            mb: 1,
          }}
          component="img"
          image={props.imagePath}
          alt="lights"
        />
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {props.details}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CardHw;
