import {
  Typography,
  Box,
  Button,
  useMediaQuery,
  Container,
} from "@mui/material";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import HomeItem from "../components/HomeItem";
import { useTheme } from "@mui/material/styles";
import ReactGA from "react-ga4";

import { appServicesList } from "../appData";

const Home = (props) => {
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  let content = appServicesList.map((data) => (
    <HomeItem itemData={data} key={data.id} />
  ));

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "home", page_title: "home" });
  }, []);

  return (
    <Container maxWidth="xl" disableGutters>
      <Box
        sx={{
          backgroundImage: "url(/assets/banner_bluered2.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          justifyItems: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          fontWeight="fontWeightMedium"
          align="center"
          variant="h3"
          color="primary"
          pt={10}
        >
          Jednoduchý a spoľahlivý inteligentný domov
        </Typography>
        <Typography
          fontWeight="fontWeightMedium"
          align="center"
          variant="h6"
          color="primary"
          pt={1}
        >
          Vypočítajte si približnú cenu pomocou našej jednoduchej kalkulačky
        </Typography>
        <Box
          sx={{
            flexDirection: matches ? "row" : "column",
            display: "flex",
            mt: 8,
            mb: 8,
          }}
        >
          <Button
            variant="contained"
            size="large"
            sx={{ m: 1 }}
            onClick={() => {
              history.push("/calculator");
            }}
          >
            Cenová kalkulačka
          </Button>
          <Button
            variant="contained"
            size="large"
            sx={{ m: 1 }}
            onClick={() => {
              history.push("/appHippoHome");
            }}
          >
            Vyskúšajte si našu aplikáciu
          </Button>
          <Button
            variant="contained"
            size="large"
            sx={{ m: 1 }}
            onClick={() => {
              history.push("/partners");
            }}
          >
            Staň sa partnerom
          </Button>
        </Box>
      </Box>
      {content}
    </Container>
  );
};

export default Home;
