import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  priceOfffers: [],
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setData(state, action) {
      state.priceOfffers = action.payload;
    },
    clearAllData(state) {
      state.priceOfffers = [];
    },
  },
});

export const dataAction = dataSlice.actions;

export default dataSlice;
