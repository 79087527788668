import React from "react";
import {
  Typography,
  Box,
  CardMedia,
  Grid,
  useMediaQuery,
  Container,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { findService } from "../appData";
import CardPage from "../components/UI/CardPage";
import { useTheme } from "@mui/material/styles";

const RestServices = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const location = useLocation();
  const data = findService(location.pathname);
  let details = data.paragraphs.filter((p) => p.detailsVisible);
  details.push({
    mainText: "Zoznam svetiel",
    details:
      "Ak Vám nevyhovuje zobrazenie svetiel v pôdoryse, môžete využiť na ovládanie osvetlenia prehľadný zoznam svetiel",
    detailsVisible: true,
    serviceImage: "/assets/lights_list.png",
  });
  details.push({
    mainText: "Nastavenia aplikácie",
    details:
      "V nastaveniach aplikácie je možné si aplikáciu prispôsobiť svojim potrebám a tiež je možné si upraviť pozície svetiel v pôdoryse do ideáleho stavu",
    detailsVisible: true,
    serviceImage: "/assets/settings.png",
  });

  const content = details.map((p) => {
    return (
      <Grid item sm={12} md={4} key={p.mainText}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            //justifyContent: "center",
            // alignSelf: "center",
            alignItems: "center",
          }}
        >
          <CardPage
            mainText={p.mainText}
            details={p.details}
            fixedHeight={matches}
            sx={{ width: "90%" }}
          />
          <CardMedia
            sx={{
              //  alignSelf: "center",
              maxWidth: 400,
            }}
            component="img"
            image={p.serviceImage}
            alt="lights"
          />
        </Box>
      </Grid>
    );
  });
  return (
    <Container
      maxWidth="xl"
      disableGutters
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h2" color="primary" mt={2} pl={2} pr={2}>
        {data.mainText}
      </Typography>
      <Typography variant="h6" color="primary" m={2}>
        {data.description}
      </Typography>
      <Grid container spacing={1} m={1}>
        {content}
      </Grid>
    </Container>
  );
};

export default RestServices;
