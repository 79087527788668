import { Typography, Container } from "@mui/material";

const AdminPage = (props) => {
  return (
    <Container
      sx={{
        mb: 2,
        mt: 3,
      }}
    >
      <Typography>Tu bude login</Typography>
    </Container>
  );
};

export default AdminPage;
