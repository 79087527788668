import React from "react";
import {
  CardMedia,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useHistory } from "react-router-dom";
import { appServicesList } from "../../appData";

const NavBar = (props) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const menuId = "primary-menu";

  const menuItems = appServicesList.map((item) => {
    return (
      <MenuItem
        key={item.mainText}
        onClick={() => {
          history.push(item.nextRoute);
          handleMenuClose();
        }}
      >
        <CardMedia
          component="img"
          sx={{ width: 40, height: 40, marginRight: 1 }}
          alt="e"
          image={item.iconName}
        />
        {item.mainText}
      </MenuItem>
    );
  });

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {menuItems}
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box sx={{ flexDirection: "row", display: "flex" }}>
            <CardMedia
              component="img"
              style={{ width: 40, marginRight: 10 }}
              image="/assets/icon.png"
              alt="hippo Icon"
              sx={{ cursor: "pointer" }}
              onClick={() => history.push("/")}
            />
            <Typography
              variant="h4"
              component="div"
              sx={{ cursor: "pointer", alignSelf: "center" }}
              onClick={() => history.push("/")}
            >
              Hippo home
            </Typography>
          </Box>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleProfileMenuOpen}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </Box>
  );
};

export default NavBar;
