import React from "react";
import PageService from "../components/UI/PageService";
import { useLocation } from "react-router-dom";
import { findService } from "../appData";

const Doorman = (props) => {
  const location = useLocation();
  const data = findService(location.pathname);
  return (
    <PageService
      pageData={data.paragraphs}
      imagePath={data.serviceImage}
      mainText={data.description}
      header={data.mainText}
      isLandscape={false}
    />
  );
};

export default Doorman;
