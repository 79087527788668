import React from "react";
import {
  Typography,
  Box,
  CardMedia,
  Grid,
  Container,
  Link,
} from "@mui/material";

import { hwData } from "../appData";
import CardHw from "../components/UI/CardHw";

const PageHw = (props) => {
  const contentController = hwData
    .filter((el) => el.controller)
    .map((el) => {
      return (
        <Grid item xs={12} md={6} xl={4} key={el.name}>
          <CardHw
            mainText={el.pdfName}
            details={el.details}
            imagePath={el.imagePath}
          ></CardHw>
        </Grid>
      );
    });
  const contentExtensions = hwData
    .filter((el) => el.extension)
    .map((el) => {
      return (
        <Grid item xs={12} md={6} xl={4} key={el.name}>
          <CardHw
            mainText={el.pdfName}
            details={el.details}
            imagePath={el.imagePath}
          ></CardHw>
        </Grid>
      );
    });
  const contentSensors = hwData
    .filter((el) => !el.extension && !el.controller)
    .map((el) => {
      return (
        <Grid item xs={12} md={6} xl={4} key={el.name}>
          <CardHw
            mainText={el.pdfName}
            details={el.details}
            imagePath={el.imagePath}
          ></CardHw>
        </Grid>
      );
    });
  return (
    <Container
      maxWidth="xl"
      disableGutters
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <Typography variant="h2" color="primary" mt={2} pl={2} pr={2}>
        Hardvérové komponenty
      </Typography>
      <Typography variant="h6" color="primary" m={2} align="center">
        Prvotriedny hardvér vyvýjaný a vyrábaný v Českej republike spoločnosťou{" "}
        <Link
          target="_blank"
          rel="noreferrer noopener"
          href="https://www.unipi.technology/"
        >
          Unipi technology.
        </Link>{" "}
        Vysoký výkon a softvérová otvorenosť spoločne s rozširovacími modulmi
        ponúkaju kvalitu a spoľahlivosť pre každú domácnosť.
      </Typography>
      <Typography variant="h3" color="primary" m={2} align="center">
        Riadiace jednotky
      </Typography>
      <Grid
        container
        spacing={1}
        sx={{
          justifyContent: "center",
          alignSelf: "center",
        }}
        mb={2}
      >
        {contentController}
      </Grid>
      <Typography variant="h3" color="primary" m={2} align="center">
        Rozširovacie Moduly
      </Typography>
      <Grid
        container
        spacing={1}
        sx={{
          justifyContent: "center",
          alignSelf: "center",
        }}
        mb={2}
      >
        {contentExtensions}
      </Grid>
      <Typography variant="h3" color="primary" m={2} align="center">
        Senzory a príslušenstvo
      </Typography>
      <Grid
        container
        spacing={1}
        sx={{
          justifyContent: "center",
          alignSelf: "center",
        }}
        mb={2}
      >
        {contentSensors}
      </Grid>
    </Container>
  );
};

export default PageHw;
