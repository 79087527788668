import { Card, CardContent, Typography } from "@mui/material";
import React from "react";

const CardPage = (props) => {
  //console.log(props.fixedHeight);
  return (
    <Card
      sx={{
        margin: 4,
        marginBottom: 1,
        borderRadius: 5,
        height: props.fixedHeight ? 150 : undefined,
        ...props.sx,
      }}
    >
      <CardContent>
        <Typography
          variant={props.fixedHeight ? "h5" : "h5"}
          component="div"
          color="primary"
        >
          {props.mainText}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {props.details}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CardPage;
