import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import React from "react";
import { useHistory } from "react-router-dom";

const HomeItem = (props) => {
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  let itemGrid = (
    <Grid
      container
      spacing={1}
      pb={3}
      sx={{ width: "100%", maxWidth: "50rem" }}
    >
      {props.itemData.paragraphs.map((p) => (
        <Grid key={p.mainText} item xs={12} md={6}>
          <Typography
            color="primary.light"
            pl={matches ? 4 : 2}
            align={matches ? "left" : "center"}
            variant={matches ? "h6" : "body2"}
          >
            <li>{p.mainText}</li>
          </Typography>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Box
      sx={{
        display: "flex",
        backgroundImage: props.itemData.imageUrl,
        backgroundPosition: "center",
        backgroundSize: "cover",
        justifyContent: props.itemData.flexContentPosition,
      }}
      pr={10}
      pl={10}
    >
      <Box
        onClick={() => {
          history.push(props.itemData.nextRoute, { itemData: props.itemData });
        }}
        sx={{
          backgroundColor: "white",
          display: "flex",
          cursor: "pointer",
          "&:hover": {
            //backgroundColor: "gray.500",
            opacity: [0.9, 0.8, 0.7],
          },
          alignSelf: "center",
          height: "80%",
          padding: 1,
          borderRadius: 4,
          flexDirection: "column",
        }}
        mt={matches ? 10 : 5}
        mb={matches ? 10 : 5}
      >
        <Typography
          sx={{ alignSelf: "center" }}
          color="primary"
          fontWeight="fontWeightMedium"
          variant="h4"
          mb={1}
        >
          {props.itemData.mainText}
        </Typography>
        {itemGrid}
      </Box>
    </Box>
  );
};

export default HomeItem;
