import {
  Typography,
  CardMedia,
  useMediaQuery,
  Link,
  Container,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ReactGA from "react-ga4";
import { useEffect } from "react";

const PagePartners = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page_title: "partnerPage" });
  }, []);
  return (
    <Container
      sx={{
        mb: 2,
        mt: 3,
      }}
    >
      <Typography variant="h3" color="primary" mb={5}>
        Chcete sa stať partnerom a inštalovať inteligentný systém Hippo home?
      </Typography>
      <CardMedia
        sx={{
          // maxWidth: 800,
          alignSelf: "center",
          mb: 1,
        }}
        component="img"
        image="/assets/partner.png"
        alt="partner"
      />
      <Typography variant="h5" color="primary" mt={3} mb={3}>
        V tom prípade nás kontaktujte na tel. čísle:{" "}
        <Link href="tel:+421918825442" variant="body">
          +421 918 825 442
        </Link>{" "}
        alebo píšte na email:
        <Link href="mailto:info@ihcontrol.eu" variant="body" ml={2}>
          info@ihcontrol.eu
        </Link>{" "}
      </Typography>
      <CardMedia
        sx={{
          // maxWidth: 800,
          alignSelf: "center",
          mb: 1,
        }}
        component="img"
        image="/assets/installer.png"
        alt="partner"
      />
      <Typography variant="h5" color="primary" mt={3} mb={3}>
        a aj Vy budete môcť využívať moderný webový nástroj na spravovanie a
        nastavovanie inteligentnej domácnosti Hippo Home.
      </Typography>
    </Container>
  );
};

export default PagePartners;
