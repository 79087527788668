import Layout from "./components/Layout/Layout";
import { Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import HippoCalculator from "./pages/HippoCalculator";
import Lightning from "./pages/Lightning";
import Doorman from "./pages/Doorman";
import Heating from "./pages/Heating";
import Security from "./pages/Security";
import Shutters from "./pages/Shutters";
import Watering from "./pages/Watering";
import RestServices from "./pages/RestServices";
import AppStorePage from "./pages/AppStorePage";

import ReactGA from "react-ga4";
import PageHw from "./pages/PageHw";
import PagePartners from "./pages/PagePartner";
import AdminPage from "./pages/AdminPage";

const TRACKING_ID = "G-XFCNRF2KWD";

if (process.env.NODE_ENV !== "development") {
  ReactGA.initialize(TRACKING_ID);
}

export default function App() {
  return (
    <Layout>
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/calculator" key={Date.now()} exact>
          <HippoCalculator />
        </Route>
        <Route path="/pageHw" exact>
          <PageHw />
        </Route>
        <Route path="/admin" exact>
          <AdminPage />
        </Route>
        <Route path="/partners" exact>
          <PagePartners />
        </Route>
        <Route path="/pageLightning" exact>
          <Lightning />
        </Route>
        <Route path="/pageDoorman" exact>
          <Doorman />
        </Route>
        <Route path="/pageHeating" exact>
          <Heating />
        </Route>
        <Route path="/pageSecurity" exact>
          <Security />
        </Route>
        <Route path="/pageShutters" exact>
          <Shutters />
        </Route>
        <Route path="/pageWatering" exact>
          <Watering />
        </Route>
        <Route path="/pageSystemFunction" exact>
          <RestServices />
        </Route>
        <Route path="/appHippoHome" exact>
          <AppStorePage />
        </Route>

        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Layout>
  );
}
