import { Typography, Box } from "@mui/material";

import React from "react";

const NotFound = (props) => {
  return (
    <Box
      sx={{
        height: 300,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <Typography align="center" variant="h4">
        Hľadaná stránka neexistuje!
      </Typography>
    </Box>
  );
};

export default NotFound;
